import React, { useEffect } from 'react';


const Blog2: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        //eslint-disable-next-line
    }, [])

    return (
        <article className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-28 mb-8">
            <h1 className="text-3xl font-bold mb-2">Minimally Invasive Spine Surgery: What You Need To Know
            </h1>
            <div className="text-gray-600 mb-4">
               🗓️ <span>14th June, 2024</span>
            </div>

            <img src='/images/blog2.jpg' alt="Minimally Invasive Spine Surgery" className="w-full h-80 object-contain rounded-lg mb-6" />

            <div className="prose max-w-none">
                <p className="mb-4">
                    Minimally invasive spine surgery (MISS) has revolutionized the treatment of various spinal conditions, offering patients a safer and quicker recovery option compared to traditional open surgeries.
                </p>

                <h2 className="text-2xl font-semibold mt-6 mb-3">Key Features of Minimally Invasive Spine Surgery</h2>
                <p className="mb-2">
                    Unlike traditional open surgery, MISS involves smaller incisions, typically less than an inch long, through which specialized surgical instruments and a camera (endoscope) are inserted. This allows surgeons to perform complex procedures with:
                </p>
                <ul className="list-disc pl-6 mb-4">
                    <li>Reduced damage to surrounding muscles and tissues</li>
                    <li>Less blood loss during the surgery</li>
                    <li>Lower risk of infection</li>
                    <li>Faster recovery times and shorter hospital stays</li>
                </ul>

                <h2 className="text-2xl font-semibold mt-6 mb-3">Conditions Treated with MISS</h2>
                <p className="mb-2">Minimally invasive techniques are effective for treating a variety of spinal conditions, including:</p>
                <ul className="list-disc pl-6 mb-4">
                    <li>Herniated discs</li>
                    <li>Spinal stenosis</li>
                    <li>Spinal tumors</li>
                    <li>Vertebral fractures</li>
                    <li>Spinal deformities (such as scoliosis)</li>
                </ul>

                <h2 className="text-2xl font-semibold mt-6 mb-3">Benefits to Patients</h2>
                <p className="mb-2">Patient benefits of minimally invasive spine surgery include:</p>
                <ul className="list-disc pl-6 mb-4">
                    <li>Quicker recovery and return to daily activities</li>
                    <li>Less post-operative pain and discomfort</li>
                    <li>Minimal scarring and improved cosmetic outcomes</li>
                    <li>Reduced risk of complications such as infections</li>
                </ul>

                <h2 className="text-2xl font-semibold mt-6 mb-3">Consulting with a Spine Specialist</h2>
                <p className="mb-4">
                    Considering minimally invasive spine surgery? Consult with a qualified spine specialist like Dr. Gangesh Gunjan in Noida NCR, India. They can evaluate your condition, discuss treatment options, and help you make an informed decision about your spine health.
                </p>

                <p className="mb-4">
                    Stay informed about the latest advancements in spine care. Minimally invasive techniques continue to evolve, offering new hope for patients seeking effective, less invasive treatment options.
                </p>
            </div>

            <button className="mt-6 bg-blue-800 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300">
                Schedule Appointment
            </button>
        </article>
    );
};

export default Blog2;