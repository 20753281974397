import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../index.css';

const Header: React.FC = () => {

  const [isScrolled, setIsScrolled] = useState<boolean | false>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean | false>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSmoothScroll = (e: React.MouseEvent<HTMLAnchorElement>, target: string) => {

    e.preventDefault();
    const element = document.getElementById(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, targetSection: string) => {
    e.preventDefault();
    const currentPath = window.location.pathname;
    if (currentPath === '/') {
      handleSmoothScroll(e, targetSection);
    } else {
      window.location.href = `/#${targetSection}`;
    }
  }

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 text-gray-800 transition-all duration-300 p-6 ${isScrolled ? 'shadow-md py-2 bg-white' : 'py-4 bg-white'}`}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex flex-row items-start space-x-3">
            <img
              src='/images/logo.png'
              alt="Dr. Gangesh Gunjan"
              className={`transition-all duration-300 ${isScrolled
                  ? 'h-[40px] md:h-[60px]'
                  : 'h-[65px] md:h-[85px]'
                }`}
            />
            <div className='flex flex-col'>
              <h1 className='text-lg md:text-2xl lg:text-4xl font-semibold mb-2'>Dr. Gangesh Gunjan</h1>
              <p className='text-[0.6rem] md:text-xs lg:text-sm lg:text-center'>M.B.B.S, MS (Gen Surg), M.Ch (Neurosurgery)</p>
            </div>
          </Link>
          <nav className="hidden lg:flex items-center space-x-8 text-lg">
            <Link to="/" className="header-link">Home</Link>
            <Link to="/#about" className="header-link" onClick={(e) => handleSmoothScroll(e, 'about')}>About</Link>
            <Link to="/#services" className='header-link' onClick={(e) => handleSmoothScroll(e, 'services')}>Services</Link>
            <Link to="/#blog" className="header-link" onClick={(e) => handleSmoothScroll(e, 'blog')}> Blog </Link>
          </nav>
          <div className="hidden lg:block">
            <Link to="/#contact" className="text-white px-4 py-3 bg-blue-800 rounded-md" onClick={(e) => handleSmoothScroll(e, 'contact')}>
              Schedule Appointment
            </Link>
          </div>
          {/* Mobile menu button */}
          <div className="lg:hidden">
            <button
              className="text-gray-600 hover:text-blue-600 focus:outline-none"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
          </div>
        </div>
        {/* Mobile menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden fixed inset-0 bg-white z-50 flex flex-col">
            <div className="p-4 pt-16">
              {/* Close button */}
              <button
                className="absolute top-4 right-4 text-gray-600 hover:text-blue-600 focus:outline-none"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <Link to="/" className="block py-2 header-link" onClick={(e) => handleNavigation(e, 'home')}>Home</Link>
              <Link to="/about" className="block py-2 header-link" onClick={(e) => handleNavigation(e, 'about')}>About</Link>
              <Link to="/#services" className="block py-2 header-link" onClick={(e) => handleNavigation(e, 'services')}>Services</Link>
              <Link to="/#blog" className="block py-2 header-link" onClick={(e) => handleNavigation(e, 'blog')}>Blog</Link>
            </div>
            {/* Divider */}
            <div className="border-t border-gray-200 my-2"></div>
            {/* Centered button */}
            <div className="p-4 flex justify-center">
              <Link to="/#contact"
                className="block w-full text-center text-center bg-blue-700 text-white p-3 rounded-lg" onClick={(e) => handleNavigation(e, 'contact')}>
                Schedule Appointment
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;