export interface ServiceSection {
    title: string;
    content?: string;
    bulletPoints?: string[];
}

export interface ServiceData {
    title: string;
    description: string;
    sections: ServiceSection[];
}

const servicesData: Record<string, ServiceData> = {
    "headInjury": {
        title: "Head Injuries and Complications",
        description: "Head injuries can lead to severe complications and require immediate, effective care to minimize damage. The type of treatment depends on the specific complications that occur after an injury.",
        sections: [
            {
                title: "Immediate Care",
                content: "Immediate and effective care is critical to minimize damage in case of any head injury. Whether it is craniotomy for severe head injuries or a minimally invasive endoscopic brain surgery, the experience and precision of the surgeon becomes critical. Immediate care is important not only to prevent further damage but also to ensure quick recovery."
            },
            {
                title: "Potential Complications",
                content: "These head injuries may also lead to the following life-threatening complications:",
                bulletPoints: [
                    "Skull fracture due to high-impact accidents which can lead to serious infections.",
                    "Accumulation of certain fluids in the brain cavity that may put pressure on the brain.",
                    "Pooling of blood outside blood vessels in the brain, also known as hematomas.",
                    "Hemorrhage due to bursting of an artery in the brain, which causes bleeding in the brain tissue."
                ]
            },
            {
                title: "Treatment",
                content: "The type of surgery required depends on the type of complications that may have occurred after an injury. Identifying the complication early, skillful surgery, prompt rehabilitation, and therapy thereafter may prove to be the point of difference in saving a life."
            }
        ]
    },
    "brainTumors": {
        title: "Tumors",
        description: "Tumors are abnormal cell growths that can multiply in the brain. They can be low-grade benign (non-cancerous) or high-grade cancerous tumors. The growth, location, and spread rate vary depending on the tumor's nature.",
        sections: [
            {
                title: "Overview",
                content: "Tumors are the abnormal growth of cells that can multiply in your brain. They can be either low-grade benign (non-cancerous) tumors, or high-grade cancerous tumors. The growth, location and the rate with which it can spread may vary depending on the nature of the tumor."
            },
            {
                title: "Common Types of Tumors",
                content: "Some of the most common tumors are listed below:",
                bulletPoints: [
                    "Glioma - Glioma falls into a broad category of brain and spinal tumors. The name is derived from glial cells that help your central nervous system work. Nearly a quarter of diagnosed primary brain tumors are Glioma.",
                    "Meningioma - This tumor grows from the membranes that surround the brain and the spinal cord. Meningioma can be hard to notice at first as it grows slowly and it could be years before you notice any symptoms.",
                    "Acoustic Tumor - Acoustic neuromas are benign or non-cancerous tumors that affect the nerves that help you maintain your balance or help you hear. It grows on the auditory nerve known as the cochlear nerve and on the vestibular nerve, which helps in maintaining balance.",
                    "Pituitary Tumor - As the name suggests, these tumors occur due to abnormal growth in the pituitary gland. Located behind your nose, the 'master' gland makes hormones that affect everything from bodily growth to metabolism."
                ]
            },
            {
                title: "Treatment",
                content: "These tumors can have varying impacts and require specific treatments depending on their type and location."
            }
        ]
    },
    "pediatricTumorsHydrocephalus": {
        title: "Pediatric Tumors & Hydrocephalus",
        description: "Pediatric tumors are abnormal cell growths in children. Hydrocephalus is a condition where excess cerebrospinal fluid (CSF) accumulates in the brain cavities.",
        sections: [
            {
                title: "Pediatric Tumors",
                content: "Tumors are developed when the body becomes incapable of moderating cell division and growth, leading to abnormal growth of cells. In children, it becomes even more concerning due to their limited ability to communicate the problem. It's important to identify the symptoms early in affected children as delay in diagnosis can impact treatment options. These symptoms can vary from a persistent headache to cranial pressure and visual disturbances."
            },
            {
                title: "Pediatric Hydrocephalus",
                content: "Hydrocephalus is a condition that is developed due to accumulation of excess fluid called cerebrospinal fluid (CSF). CSF is found in brain cavities called ventricles and helps in washing away impurities, carrying nutrients and cushioning the brain and spinal cord. It is critical to balance the flow, production and absorption of CSF to maintain normal pressure inside the skull. In children, it can be developed either after birth or during pregnancy in other cases:"
            },
            {
                title: "Types of Hydrocephalus",
                bulletPoints: [
                    "Congenital hydrocephalus - This condition is caused during fetal development due to a complicated interaction of environmental and genetic factors. In some cases, the condition may exist at birth but develops in the later parts of the child's lifetime.",
                    "Acquired hydrocephalus - This type of hydrocephalus develops after birth and the most common causes are internal bleeding in the brain, head injuries, tumors or any type of infection."
                ]
            }
        ]
    },
    "endoscopicBrainSurgery": {
        title: "Endoscopic Brain Surgery",
        description: "Endoscopic brain surgery is a minimally invasive approach to reach regions in the brain for treating different neurological disorders.",
        sections: [
            {
                title: "Overview",
                content: "Endoscopic brain surgery is a minimally invasive approach to reach regions in the brain for treating different neurological disorders. With the latest technology, it has become increasingly prevalent to access hard-to-reach areas in the brain with minimal incisions. Thin tubes with a camera on the end, called an endoscope, are inserted into the brain through these small incisions. The images of the affected area are then used as a guide for higher precision. Neuroendoscopy, as it's also called, is primarily used to remove benign brain tumors."
            },
            {
                title: "Benefits",
                content: "Its use is widening due to the following benefits:",
                bulletPoints: [
                    "Faster recovery compared with open surgical procedures",
                    "Reduced risk of side effects and lower duration of hospital stay",
                    "Less pain post-surgery due to minimally invasive procedure",
                    "Lower exposure and risk of damage to surrounding brain tissue",
                    "Well tolerated by patients with low risk of complications"
                ]
            },
            {
                title: "Patient-Doctor Communication",
                content: "Choosing the right treatment means strong patient-doctor communication in the initial consultations to make the patient understand the right procedure and the risks involved. Book an appointment with us to begin your treatment journey."
            }
        ]
    },
    "brainAneurysms": {
        title: "Brain Aneurysms",
        description: "A brain aneurysm is a weak spot in a brain artery that bulges out with blood, potentially putting pressure on the brain or nerve tissue.",
        sections: [
            {
                title: "Overview",
                content: "When a weakened area in the wall of a blood vessel shows signs of abnormal bulging, it's called an aneurysm. Although it can affect any blood vessel, a brain aneurysm is a weak spot in a brain artery that bulges out with blood. It can put pressure on the brain or the nerve tissue. A ruptured vessel is known as hemorrhage, causing dangerous health problems such as brain damage, coma, stroke or even death. As an aneurysm can progressively increase in size with the artery wall getting increasingly weaker, surgical intervention becomes significantly important."
            },
            {
                title: "Symptoms",
                content: "Brain aneurysms cause symptoms only if they rupture, except in cases where the bulge is large enough to put pressure on nerves and tissues in the brain. In those cases, following symptoms can be observed:",
                bulletPoints: [
                    "Pain around or above your eye",
                    "Double vision or loss of vision",
                    "Headaches and difficulty in speaking",
                    "Difficulty in concentrating and loss of balance",
                    "Weakness or numbness on one side of face"
                ]
            },
            {
                title: "Rupture Symptoms",
                content: "In case of a rupture, the patient may feel severe headache along with blurred or double vision, confusion, nausea, stiff neck, loss of consciousness, fits and weakness in one side of the body. Consult with an experienced neurosurgeon at the first signs of any of those symptoms."
            }
        ]
    },
    "epilepsySurgery": {
        title: "Epilepsy Surgery",
        description: "Epilepsy surgery is performed to stop or limit the severity of seizures when medicines are not effective in controlling them.",
        sections: [
            {
                title: "Overview",
                content: "Epilepsy surgery is performed to stop or limit the severity of seizures. Surgery may be an option when medicines are not effective in controlling these seizures. It's also done to decrease the possible side effects of the medicines or decrease use of those medicines altogether. The type of surgery needed depends on various factors including the age of the patient and the location of the affected neurons."
            },
            {
                title: "Health Risks and Complications",
                content: "If not controlled, seizures can lead to the following health risks and complications:",
                bulletPoints: [
                    "Developmental complications in children",
                    "Physical injuries during seizures",
                    "Sudden death in rare cases",
                    "Anxiety and depression",
                    "Deteriorating memory or thinking skills"
                ]
            },
            {
                title: "Holistic Approach",
                content: "However, it's also about living with the condition as recurrent seizures can affect the emotional, mental, and social well-being of the patient. Therefore, a holistic approach is needed to address the emotional and mental aspects after surgery. Prioritize your well-being and book an appointment to get the best epilepsy neurosurgical care."
            }
        ]
    },
    "neuronavigation": {
        title: "Neuronavigation",
        description: "Neuronavigation is performed with the help of advanced imagery, pinpointing the affected part with great precision in both brain and spine surgeries.",
        sections: [
            {
                title: "Overview",
                content: "Neuronavigation is performed with the help of advanced imagery, pinpointing the affected part with great precision. It finds application in both brain and spine surgeries, making them much safer compared to earlier methods and improving the overall quality of surgical care."
            },
            {
                title: "How It's Done?",
                content: "Neuronavigation gives the neurosurgeon the ability to see a patient's anatomy in 3D with the help of diagnostic tools such as CT (Computer Tomography) and MRI (Magnetic Resonance Imaging). With the combination of images obtained during the course of the surgery, the technique helps the surgeon in tracking the instrumentation and surgical procedure in real-time."
            },
            {
                title: "Benefits",
                content: "Neuronavigation has various benefits as it allows the doctor to accurately detect where he is working at each moment during the surgery. This helps the patient in multiple ways including smaller incisions, minimal exposure, less trauma, lower complications, better planning of the surgery and improved post-trauma care. This technology is critical in cases where the tumors are located deeper in the brain. Preserving critical brain structures and minimizing the risk of an incorrect approach becomes the priority in such cases. Reach out to us for the outstanding combination of right technology and guidance."
            }
        ]
    },
    "fluorescenceGuidedSurgery": {
        title: "Fluorescence Guided Surgery",
        description: "Fluorescence guided surgery uses a fluorescent substance and a special microscope to see edges of the tumor more sharply during surgery.",
        sections: [
            {
                title: "Overview",
                content: "This technique is also part of new emerging intraoperative technologies which help surgeons during the course of the surgery. Fluorescence guided surgery uses a fluorescent substance and a special microscope to see edges of the tumor more sharply. The substance lights up with a special blue light under the microscope, which helps the neurosurgeon in removing the tumor completely without hurting any surrounding healthy tissue. Studies have shown that tumors can be removed more effectively with this technique when compared with conventional methods."
            },
            {
                title: "Benefits",
                content: "With the help of real-time visualization of the tumor during the course of the surgery, the precision of the surgery increases significantly and the chances of any injury are lowered. Applications of this image-guided surgery have evolved over the past several decades and the technique has now become widespread. It can illuminate tissues which may have been difficult to differentiate from its surroundings via other techniques."
            },
            {
                title: "Our Expertise",
                content: "You can rely on us for the smooth execution of this surgical vision enhancing method."
            }
        ]
    },
    "degen": {
        title: "Fluorescence Guided Surgery",
        description: "Fluorescence guided surgery is an advanced intraoperative technique that enhances tumor visibility, improving precision in complex procedures such as those for degenerative spine diseases.",
        sections: [
            {
                title: "Overview",
                content: "Fluorescence guided surgery is part of new emerging intraoperative technologies which help surgeons during the course of the surgery. It uses a fluorescent substance and a special microscope to see edges of the tumor more sharply. The substance lights up with a special blue light under the microscope, which helps the neurosurgeon in removing the tumor completely without hurting any surrounding healthy tissue."
            },
            {
                title: "Benefits",
                content: "Studies have shown that tumors can be removed more effectively with this technique when compared with conventional methods. With the help of real-time visualization of the tumor during the course of the surgery, the precision of the surgery increases significantly and the chances of any injury are lowered. Applications of this image-guided surgery have evolved over the past several decades and the technique has now become widespread. It can illuminate tissues which may have been difficult to differentiate from its surroundings via other techniques."
            },
            {
                title: "Complex Degenerative Spine Disease",
                content: "Complex degenerative spine disease is an umbrella term for various diseases caused by loss of function, mobility or strength of the spine. Often caused by usual deterioration of the spine as we age, these diseases can severely impact your lifestyle or add to pains of other medical conditions. The complex parts in the spine include joints, bones, nerves, muscles, discs and ligaments. Any disease from stenosis to sciatica or pinched nerves is categorized under this term."
            },
            {
                title: "Symptoms and Treatment",
                content: "Symptoms of complex degenerative spine disease could include weakness, pain and tingling radiating to even your limbs. Surgery is needed only when non-operative care becomes ineffective. While it may seem a little overwhelming to opt for a spine surgery, the step may help you to finally get back to a normal life free from the constant pain. Fluorescence guided surgery can be particularly beneficial in these complex cases, allowing for more precise tumor removal and minimizing damage to healthy tissue."
            },
            {
                title: "Our Approach",
                content: "We take the time to explain to you different risks, procedures, options and recovery times to help you choose the best treatment. We ensure the right surgery with the use of advanced technology like fluorescence guided surgery and the latest tools available at our disposal. You can rely on us for the smooth execution of these surgical techniques, enhancing the precision and safety of complex spine surgeries."
            }
        ]
    },
    "surgeryForBonyTumors": {
        title: "Surgery For Bony Tumors",
        description: "Spinal tumor surgery is performed to treat tumors developed anywhere on the spinal column, from tissues and vertebrae to the spinal cord.",
        sections: [
            {
                title: "Overview",
                content: "Spinal tumor surgery is done to treat tumors developed anywhere on the spinal column from tissues, vertebrae to the spinal cord. These tumors could have either affected the spine primarily or they can be metastatic, meaning the tumor has spread from any other part of the body to the spine. A spinal tumor is the abnormal growth of tissues in the spinal column. Depending upon the location of the tumor, pain can be felt in the spine or the other parts of the body."
            },
            {
                title: "Symptoms and Effects",
                content: "If any spinal nerve becomes damaged or compressed, its neurological effect can be observed from weakness or numbness in the limbs and can lead to paralysis in extreme cases."
            },
            {
                title: "Treatment Approach",
                content: "Timely diagnosis and immediate treatment have a huge impact on the patient's hope for a cure and overall health. We take into consideration a variety of factors including morbidity of the tumor, associated blood loss and the overall health of the patient before deciding the right surgical care. Call now to consult the neurosurgeon."
            }
        ]
    },
    "spineTumors": {
        title: "Spine Tumors",
        description: "Spinal tumors can be benign or malignant (cancerous) and can cause problems by damaging different tissues in the spinal column.",
        sections: [
            {
                title: "Overview",
                content: "Spinal tumors can be benign or malignant (cancerous). Constant growth of the tumor can cause problems by damaging different tissues in the spinal column. However, there are more categories within this term that patients should understand."
            },
            {
                title: "Categories",
                content: "These are two broad categories of the spinal tumors:",
                bulletPoints: [
                    "Intramedullary - When the spinal tumor is located within the column i.e. within the cord parenchyma, it is known as intramedullary.",
                    "Extramedullary - These tumors are further divided into two categories namely intradural and extradural, referring to spinal dura mater - the outermost layers of tissue surrounding the spinal cord. When a tumor is located within the dura but outside the spinal cord, it is intradural. When it is located outside the dura, it is known as extradural."
                ]
            },
            {
                title: "Effects and Treatment",
                content: "These tumors can put pressure on the surrounding tissues causing neurological consequences. Surgical removal can resolve these problems in many cases. However, radiation therapy can be used in other cases. Depending on the nature of the tumor, a combination of both can also be used for treatment."
            }
        ]
    },
    "minimallyInvasiveKeyholeSurgeries": {
        title: "Minimally Invasive/Keyhole Surgeries",
        description: "Minimally invasive spine surgery requires tiny incisions to operate on the affected area in the spine, ensuring minimal damage to bones, muscles and ligaments.",
        sections: [
            {
                title: "Overview",
                content: "Minimally invasive spine surgery requires tiny incisions to operate on the affected area in the spine. These small incisions are the size of a keyhole, hence the name keyhole surgery. Performed using an instrument called tubular retractor, the surgery creates a tunnel-like opening. It ensures minimal damage to bones, muscles and ligaments, while also keeping the blood loss as low as possible."
            },
            {
                title: "How It's Done?",
                content: "To gain access to the spinal area, the muscle tissue needs to be pushed out of the way as the spinal cord and intervertebral nerve are located deep in the body. After creating a tunnel-like opening, the doctor inserts an endoscope or uses a surgical microscope to get a clear image of the operating area. Mini surgical instruments are used to operate on the affected area and then the incision is stitched back."
            },
            {
                title: "Our Expertise",
                content: "Performing this surgery requires advanced surgical skills and training based on the latest technology. Reach out to us to know whether a minimal invasive surgery is the way to go for your problem or a conventional surgery suits better."
            }
        ]
    },

    "spinalDysraphisms": {
        title: "Spinal Dysraphisms",
        description: "Spinal Dysraphism describes different conditions that affect a baby's spinal cord, spine or nerve roots, where the spinal cord or spine doesn't form properly during pregnancy.",
        sections: [
            {
                title: "Overview",
                content: "Spinal Dysraphism describes different conditions that affect a baby's spinal cord, spine or nerve roots. In this condition, the spinal cord or spine doesn't form properly during pregnancy, which can include both visible and invisible incomplete formations. In some cases, the incomplete formation may be visible on the skin's surface but it might not be visible in other cases."
            },
            {
                title: "Occurrence and Diagnosis",
                content: "It's a rare condition, which is slightly more common in females than males. Development of spinal dysraphisms can be traced to early stages of the pregnancy, when a section of neural tube that's supposed to develop into the spine and spinal cord doesn't close completely. Depending on the type of spinal dysraphism, the diagnosis of the specific condition can vary in a patient's lifetime. For example, a severe type of dysraphism is Myelomeningocele, which is diagnosed before birth using ultrasound."
            },
            {
                title: "Causes and Prevention",
                content: "Causes of this condition are understood to be both genetic and environmental. Higher focus on maternal nutrition during pregnancy, with particular focus on folate, is understood to work against some forms of this condition."
            }
        ]
    },

    "spinalTrauma": {
        title: "Spinal Trauma (Fractures etc)",
        description: "Spinal trauma due to falls, violence and road traffic injuries can have a serious impact on someone's life and can lead to risks of developing life-threatening secondary conditions.",
        sections: [
            {
                title: "Overview",
                content: "Spinal trauma due to falls, violence and road traffic injuries can have a serious impact on someone's life, but can also lead to risks of developing life-threatening secondary conditions. According to the World Health Organisation, over 15 million people are living with spinal cord injuries caused by similar traumas. The injury can involve damage to any part of the spinal cord, including damage to the nerves at the end of the spinal cord. Such injuries can cause severe changes in feeling, strength and body functions below the spot of the injury. The patient may also experience social, emotional and mental costs."
            },
            {
                title: "Symptoms",
                content: "Spinal trauma can cause the following symptoms:",
                bulletPoints: [
                    "Change or loss in sensation, including the ability to feel touch, cold and heat",
                    "Loss of movement, including bladder and bowel control",
                    "Change in sexual sensitivity, function and fertility",
                    "Trouble coughing, breathing or clearing secretions from the lungs",
                    "Sting sensation or pain caused by damage to the nerve fibres",
                    "Exaggerated spasms or reflex activities"
                ]
            },
            {
                title: "Treatment",
                content: "Immediate medical attention is needed in cases of spinal trauma. Once the patient is stabilized, special attention to various aspects of the injury from physical to emotional can lead to improved quality of life and better recovery. Call now to get outstanding neurosurgical care."
            }
        ]
    },
    "sciatica": {
        title: "Sciatica",
        description: "Sciatica is pain that radiates along the path of the sciatic nerve, which branches from your lower back through your hips and buttocks and down each leg.",
        sections: [
            {
                title: "Overview",
                content: "Sciatica occurs when the sciatic nerve is compressed or irritated, usually by a herniated disk or bone spur in the spine. It typically affects only one side of the body and can range from a mild ache to severe, shooting pain."
            },
            {
                title: "Symptoms",
                content: "Common symptoms include lower back pain that extends through the hip and buttock down the leg, numbness or weakness in the affected leg or foot, and a tingling or pins-and-needles sensation in the leg."
            },
            {
                title: "Treatment",
                content: "Treatment often begins with conservative methods like physical therapy and pain medication. In severe cases or when conservative treatments fail, surgery may be considered to relieve nerve compression."
            }
        ]
    },

    "carpeTunnel": {
        title: "Carpal Tunnel Syndrome",
        description: "Carpal tunnel syndrome is a condition that causes numbness, tingling and other symptoms in the hand and arm due to a compressed nerve in the carpal tunnel, a narrow passageway on the palm side of your wrist.",
        sections: [
            {
                title: "Causes",
                content: "It's often the result of repetitive hand motions, certain health conditions like diabetes or rheumatoid arthritis, or anatomic factors such as a smaller carpal tunnel."
            },
            {
                title: "Symptoms",
                content: "Symptoms typically include numbness, tingling, and pain in the thumb, index, middle, and ring fingers. In advanced cases, there may be weakness in the hand and difficulty gripping objects."
            },
            {
                title: "Treatment",
                content: "Treatment options range from wrist splinting and lifestyle changes to corticosteroid injections. In severe cases, a surgical procedure called carpal tunnel release may be necessary to relieve pressure on the median nerve."
            }
        ]
    },

    "peripheralNerveDisorders": {
        title: "Peripheral Nerve Disorders",
        description: "Peripheral nerve disorders affect the nerves outside the brain and spinal cord, including sensory, motor, and autonomic nerves.",
        sections: [
            {
                title: "Types",
                content: "These disorders can include mononeuropathies (affecting a single nerve) like ulnar nerve palsy, polyneuropathies (affecting multiple nerves) such as diabetic neuropathy, and neuritis (inflammation of nerves)."
            },
            {
                title: "Symptoms",
                content: "Symptoms vary depending on the nerves affected but may include numbness, tingling, burning pain, muscle weakness, and in some cases, problems with organ function."
            },
            {
                title: "Treatment",
                content: "Treatment depends on the underlying cause and may include medications to relieve pain, physical therapy, lifestyle changes, and in some cases, surgical intervention to repair or decompress affected nerves."
            }
        ]
    }
};

export default servicesData