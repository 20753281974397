import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import  servicesData  from "../components/utility/ServiceData";

interface ServiceCardProps {
    title: string;
    description: string;
    slug: string;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, slug }) => {
    return (
        <div className="bg-white shadow-md rounded-[30px] p-6 items-center text-center hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-2">{title}</h3>
            <p className="text-gray-600 mb-12">{description}</p>
            <Link to={`/services/${slug}`}>
                <span className="text-white bg-blue-800 p-4 rounded-lg mb-4 hover:bg-blue-700">Learn more &rarr;</span>
            </Link>
        </div>
    );
};

const BrainServices: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        //eslint-disable-next-line
      }, [])
    const serviceEntries = Object.entries(servicesData).slice(0, 7);

    return (
        <div className="container mx-auto px-4 py-8 mt-28">
            <h2 className="text-3xl font-bold mb-8 text-center">Brain Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {serviceEntries.map(([slug, service]) => (
                    <ServiceCard
                        key={slug}
                        title={service.title}
                        description={service.description}
                        slug={slug}
                    />
                ))}
            </div>
        </div>
    );
};

export default BrainServices;