import React from 'react';
import { FaCalendarAlt, FaUserMd, FaClinicMedical } from 'react-icons/fa';
import { useForm, ValidationError } from "@formspree/react";
import '../index.css';



const BookAppointment: React.FC = () => {

  const [state, handleSubmit] = useForm('mkgwyjyo');
  
  console.log(state);
  

  return (
    <div className="appointment relative py-24 overflow-hidden">
      <div className="absolute inset-0 bg-blue-100 opacity-20 transform -skew-y-6"></div>
      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 mb-12 lg:mb-0">
            <h2 className="text-4xl font-bold mb-6 text-blue-700">Book Your Appointment</h2>
            <p className="text-xl text-blue-400 mb-8">
              Schedule a consulation with nation's top Neurosurgeon Dr. Gangesh Gunjan today.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8 text-zinc-600">
              <FeatureCard
                icon={<FaUserMd className="text-zinc-900" />}
                title="Expert Care"
                description="Consult with a leading Neurosurgeon"
              />
              <FeatureCard
                icon={<FaCalendarAlt className="text-zinc-900" />}
                title="Flexible Scheduling"
                description="Choose a time that suits you best"
              />
              <FeatureCard
                icon={<FaClinicMedical className="text-zinc-900" />}
                title="State-of-the-art Facility"
                description="Visit our modern clinic."
              />
            </div>
          </div>
          <div className="lg:w-1/2 bg-white rounded-lg shadow-2xl p-8">
            <div className="relative w-full h-[400px] flex items-center justify-center" >
              {state.succeeded ? (
                <>
                  <p className='text-blue-700 text-2xl'>Your Form has been sent successfully!</p>
                </>

              ) : (
                <form onSubmit={handleSubmit} className="space-y-4">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    placeholder="Full Name"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <input
                    type="email"
                    name="email"
                    id="email"

                    placeholder="Email Address"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ValidationError prefix="Email" field="email" errors={state.errors} />
                  <input
                    type="tel"
                    name="phone"
                    id="phone"

                    placeholder="Phone Number"
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <input
                    type="date"
                    name="date"
                    id='date'

                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ValidationError prefix="Date" field="date" errors={state.errors} />

                  <textarea
                    name="message"
                    id="message"
                    placeholder="Information Query"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  ></textarea>
                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="w-full bg-blue-900 text-white font-semibold py-3 px-6 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105"
                  >
                    {state.submitting ? 'Submitting....' : 'Book Appointment'}
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 w-64 h-64 bg-blue-400 rounded-full opacity-20 transform translate-x-1/2 translate-y-1/2"></div>
    </div>
  );
};

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description }) => (
  <div className="flex items-center space-x-4">
    <div className="text-4xl">{icon}</div>
    <div>
      <h3 className="text-xl font-semibold text-gray-500">{title}</h3>
      <p className="text-blue-500">{description}</p>
    </div>
  </div>
);

export default BookAppointment;