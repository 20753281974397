import React, {useEffect} from "react";
import servicesData from "../components/utility/ServiceData";
import { ServiceCard } from "./BrainServices";

const SpineServices: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        //eslint-disable-next-line
      }, [])
    const serviceEnteries = Object.entries(servicesData).slice(8, 14);
    return (
        <div className="container mx-auto px-4 py-8 mt-28">
            <h2 className="text-3xl font-bold mb-8 text-center">Spine Services</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {serviceEnteries.map(([slug, service]) => (
                    <ServiceCard
                        key={slug}
                        title={service.title}
                        description={service.description}
                        slug={slug}
                    />
                ))}
            </div>
        </div>
    )
}

export default SpineServices