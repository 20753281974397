import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import servicesData, { ServiceData } from "./ServiceData";

const ServiceTemplatePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, []);

  const { serviceId } = useParams<{ serviceId: string }>();
  const service: ServiceData | undefined = servicesData[serviceId as keyof typeof servicesData];

  if (!service) {
    return <div>Service not found</div>;
  }

  return (
    <main className="flex-grow px-6 py-8">
      <section className="mx-auto px-4 py-8 mt-20 bg-background">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold mb-4 text-center text-foreground font-fraunces-slab">{service.title}</h1>
          <p className="mb-8 font-work-sans text-lg">{service.description}</p>
          
          {service.sections.map((section, index) => (
            <div key={index} className="mb-8">
              <h2 className="text-2xl font-bold mb-4 text-foreground font-fraunces-slab">{section.title}</h2>
              <p className="mb-4 font-work-sans">{section.content}</p>
              {section.bulletPoints && (
                <ul className="list-disc pl-5 mb-4">
                  {section.bulletPoints.map((point, pointIndex) => (
                    <li key={pointIndex} className="mb-2 font-work-sans">{point}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </section>
    </main>
  );
};

export default ServiceTemplatePage;